import React, { useEffect, useState } from 'react'
import ReactECharts from 'echarts-for-react'
// import useFetchData from './services/useFetch'
import { customRequest } from '../services/httpClient'
// import * as ReactECharts from 'echarts-for-react'

const Page: React.FC = ({ title, ctypeVar }) => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState()
  // const formatLabel = (x) => {
  //   x = parseInt(x)
  //   return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // }
  useEffect(() => {
    console.log('ctypeVar', ctypeVar)
    customRequest
      .post('https://pos.public.uz/fserver/getDaily', {
        ctype: ctypeVar,
      })
      .then((response) => {
        setData(response.data)
        setLoading(false)
      })
  }, [ctypeVar])

  if (isLoading) {
    return <div className="App">Loading...</div>
  }
  let option = {}
  switch (ctypeVar) {
    case 'kunlisavdo':
      option = {
        title: {
          text: title,
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: Object.values(data.names),
          selected: data.legends,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data.days,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: data.names['month1'],
            type: 'line',
            visible: false,
            data: data.month1,
          },
          {
            name: data.names['month2'],
            type: 'line',

            data: data.month2,
          },
          {
            name: data.names['month3'],
            type: 'line',

            data: data.month3,
          },
          {
            name: data.names['month4'],
            type: 'line',

            data: data.month4,
          },
          {
            name: data.names['month5'],
            type: 'line',

            data: data.month5,
          },
        ],
      }
      break
    case 'oylisavdo':
      option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {
          data: ['Foyda', 'Rasxod', 'Sof'],
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: data.legends,
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        color: [
          '#0065c2',
          '#FF8000',
          '#57904b',
          '#d35b5c',
          '#26c238',
          '#9876aa',
          '#fb8649',
        ],
        series: [
          {
            name: 'Foyda',
            type: 'bar',
            barGap: 0,
            label: {
              show: true,
              position: 'top',
              formatter: function (params) {
                console.log(params.value)
                return params.value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              },
            },

            emphasis: {
              focus: 'series',
            },
            data: data.dataFoyda,
          },
          {
            name: 'Rasxod',
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              formatter: function (params) {
                console.log(params.value)
                return params.value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              },
            },

            emphasis: {
              focus: 'series',
            },
            data: data.dataRasxod,
          },
          {
            name: 'Sof',
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              formatter: function (params) {
                console.log(params.value)
                return params.value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              },
            },

            emphasis: {
              focus: 'series',
            },
            data: data.dataSof,
          },
        ],
      }
      break
    default:
      break
  }

  return <ReactECharts option={option} style={{ height: 400 }} />
}

export default Page
