import React, { useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { customRequest } from '../services/httpClient'
const columns = [
  { field: 'id', headerName: 'ID', width: 150 },

  {
    field: 'MAHSULOT',
    headerName: 'MAHSULOT NOMI',
    width: 200,
  },
  {
    field: 'SOTUVLAR_SONI',
    headerName: 'SOTUVLAR SONI',
    width: 200,
    type: 'number',
  },
  {
    field: 'QOLDIQ',
    headerName: 'QOLDIQ',
    width: 200,
    type: 'number',
  },
  {
    field: 'code',
    headerName: 'SHTRIX KOD',
    width: 200,
  },
]

// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ]
export default function DenseTable() {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState()
  useEffect(() => {
    customRequest
      .get('https://pos.public.uz/fserver/getTopSales')
      .then((response) => {
        setData(response.data)
        setLoading(false)
      })
  }, [])

  if (isLoading) {
    return <div className="App">Loading...</div>
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid rows={data} columns={columns} pageSize={5} dense />
    </div>
  )
}
