import { fetchUtils } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'

// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com')
const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const { token } = JSON.parse(localStorage.getItem('auth'))
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}
const dataProvider = simpleRestProvider(
  'https://pos.public.uz/fserver',
  httpClient
)
export default dataProvider
