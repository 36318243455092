import * as React from 'react'
import { Admin, Resource } from 'react-admin'
// import jsonServerProvider from 'ra-data-json-server'
// import { UserList } from './users'
// import { PostList, PostEdit, PostCreate } from './posts'
// import PostIcon from '@material-ui/icons/Book'
import { ProductList, ProductCreate, ProductEdit } from './pages/products'
import ProductIcon from '@material-ui/icons/Book'
import Dashboard from './Dashboard'
import authProvider from './authProvider'
import dataProvider from './services/dataProvider'

const App = () => {
  return (
    <Admin
      authProvider={authProvider}
      dashboard={Dashboard}
      dataProvider={dataProvider}
    >
      <Resource
        name="products"
        icon={ProductIcon}
        list={ProductList}
        create={ProductCreate}
        edit={ProductEdit}
      />
      {/* <Resource
      name="posts"
      list={PostList}
      edit={PostEdit}
      create={PostCreate}
      icon={PostIcon}
    />
    <Resource name="users" list={UserList} icon={ProductIcon} /> */}
    </Admin>
  )
}

export default App
