// in src/Dashboard.js
import * as React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Chart from './components/simple_chart'
import TopSales from './components/topSales'

const Dashboard = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Admin Panelga Xush kelibsiz" />
          <CardContent>
            <Chart title="Kunli Savdo" ctypeVar="kunlisavdo" />
            <Chart title="Kunli Savdo" ctypeVar="oylisavdo" />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Oxirgi 7 kunda eng kop ketgan tovarlar royhati" />
          <CardContent>
            <TopSales />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Dashboard
