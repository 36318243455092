import * as React from 'react'
import { useMediaQuery } from '@material-ui/core'
import {
  List,
  Datagrid,
  TextField,
  // ReferenceField,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  // ReferenceInput,
  // SelectInput,
  TextInput,
  SimpleList,
  NumberInput,
  DateField,
  Toolbar,
  SaveButton,
  DateInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

// const PostTitle = ({ record }) => {
//   return <span>Post {record ? `"${record.name}"` : ''}</span>
// }
const postFilters = [
  <TextInput source="q" label="Search" alwaysOn />,
  // <ReferenceInput source="userId" label="User" reference="users" allowEmpty>
  //   <SelectInput optionText="name" />
  // </ReferenceInput>,
]

export const ProductList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <List {...props} filters={postFilters}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => `${record.quantity} quantity`}
          tertiaryText={(record) => record.code}
        />
      ) : (
        <Datagrid>
          <TextField source="id" />
          <TextField source="code" />
          <TextField source="name" />
          <TextField source="description" />
          <TextField source="init_price" />
          <TextField source="last_price" />
          <TextField source="expiry_date" />
          <TextField source="quantity" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <EditButton />
        </Datagrid>
      )}
    </List>
  )
}

const CustomToolbar = ({ permissions, allow, ...props }) => {
  let saveAllow = permissions
    ? permissions.products
      ? permissions['products'].includes(allow)
      : false
    : false
  return (
    <Toolbar {...props} classes={useStyles()}>
      {saveAllow && <SaveButton label="SAQLA" redirect="list" />}
    </Toolbar>
  )
}
const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export const ProductCreate = ({ permissions, ...props }) => (
  <Create {...props}>
    <SimpleForm
      toolbar={<CustomToolbar permissions={permissions} allow="create" />}
    >
      <TextInput disabled source="id" />
      <TextInput source="code" />
      <TextInput source="name" />
      <TextInput source="description" />
      <NumberInput source="init_price" />
      <NumberInput source="last_price" />
      <DateInput source="expiry_date" />
      <NumberInput source="quantity" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleForm>
  </Create>
)
export const ProductEdit = ({ permissions, ...props }) => (
  <Edit {...props}>
    <SimpleForm
      toolbar={<CustomToolbar permissions={permissions} allow="update" />}
    >
      <TextInput disabled source="id" />
      <TextInput source="code" />
      <TextInput source="name" />
      <TextInput source="description" />
      <NumberInput source="init_price" />
      <NumberInput source="last_price" />
      <DateInput source="expiry_date" />
      <NumberInput source="quantity" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </SimpleForm>
  </Edit>
)
