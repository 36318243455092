/* eslint-disable import/no-anonymous-default-export */
export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request('https://pos.public.uz/fserver/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then((auth) => {
        localStorage.setItem('auth', JSON.stringify(auth))
        localStorage.setItem('accessToken', auth.token)
      })
      .catch(() => {
        throw new Error('Network error')
      })
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('auth')
    localStorage.removeItem('accessToken')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: (error) => {
    const status = error.status
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth')
      localStorage.removeItem('accessToken')
      return Promise.reject()
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () =>
    localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject({ message: false }),
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = JSON.parse(localStorage.getItem('auth'))
    if (role) {
      return role.ability ? Promise.resolve(role.ability) : Promise.reject()
    } else {
      return Promise.reject()
    }
  },
}
